import React from 'react'

const PageTitle = props => {
    const { title, subtitle } = props
  return (
    <div className='bg-secondary-light px-4 py-5 mx-auto md:py-12 2xl:py-16 md:text-center'>
        <h1 className=" text-xl 2xl:text-2xl text-primary pb-2 md:pb-0">{title}</h1>
        <p className="text-sm md:text-base 2xl:text-lg">{subtitle}</p>
    </div>
  )
}

export default PageTitle