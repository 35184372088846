import * as React from "react"
import Layout from "../components/layout"
import PageTitle from "../components/pageTitle"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <PageTitle title="404: Page Not Found"/>
  </Layout>
)

export default NotFoundPage
